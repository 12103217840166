import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  background-image: url(/images/official/common/bgPublic02.png);
  background-color: #ffffff;
  background-size: cover;
  border-bottom: solid 4px #dcdee1;

  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;
    width: 20%;
    border-bottom: solid 4px #dcdee1;
    bottom: -16px;
  }

  &:after {
    right: 0;
  }

  .p-low-head-inner {
    display: flex;
    max-width: 1366px;
    margin: 0 auto;
    padding: 34px 0;
    justify-content: center;

    .p-inner-txt {
      p {
        font-weight: 800;
        font-size: 2.5em;
        text-align: center;
        margin: 0 auto 20px;
        white-space: pre-wrap;
      }

      h2 {
        font-size: 1em;
        font-weight: 400;
        margin: 0 auto 10px;
        text-align: center;
        width: calc(100vw - 80px);
      }
    }
  }

  ${BreakPoints.largest} {
  }

  ${BreakPoints.medium} {
  }
`

type Props = {
  /** クラス名 */
  className?: string
  /** タイトル */
  label?: string
  /** 補足テキスト */
  description?: string
  /** 内容（画像） */
  children?: ReactNode
}

/**
 * サービスサイト下層用ヘッダー
 * @param props
 * @constructor
 */
const OfficialLowHeader = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <Wrapper className={className}>
      <div className="p-low-head-inner">
        <div className="p-inner-txt">
          {props.label && <p>{props.label}</p>}
          {props.description && <h2>{props.description}</h2>}
          {props.children}
        </div>
      </div>
    </Wrapper>
  )
}

export default OfficialLowHeader
