import styled from '@emotion/styled'
import { navigate } from 'gatsby-link'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.section`
  width: 100%;
  background: linear-gradient(to bottom right, #41c9b3 0%, #3de8dc 100%);
  text-align: center;
  position: relative;
  .p-cta-inner {
    max-width: 1366px;
    margin: 0 auto;
    padding: 60px 0;
    h3 {
      font-size: 2.5em;
      color: #005e6a;
    }
    .p-cta-ribbon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 0 0 0;
      width: 476px;
      height: 60px;
      margin: 20px auto 10px;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22522.004%22%20height%3D%2288.367%22%20viewBox%3D%220%200%20522.004%2088.367%22%3E%20%3Cdefs%3E%20%3Cstyle%3E%20.cls-1%20%7B%20fill%3A%20%23009893%3B%20%7D%20%3C%2Fstyle%3E%20%3C%2Fdefs%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_2240%22%20data-name%3D%22%E3%83%91%E3%82%B9%202240%22%20class%3D%22cls-1%22%20d%3D%22M687.13%2C549.26c-.1-.106-10.287-10.71-14.332-24.91a96.34%2C96.34%2C0%2C0%2C1-3.3-26.006%2C1.383%2C1.383%2C0%2C0%2C0-1.189-1.512H647.051L643.02%2C478.28a1.3%2C1.3%2C0%2C0%2C0-1.205-1.1H216.928l-3.194-12.708a1.366%2C1.366%2C0%2C0%2C0-.838-.981%2C1%2C1%2C0%2C0%2C0-.358-.056H166.787a1.367%2C1.367%2C0%2C0%2C0-1.24%2C1.464%2C1.574%2C1.574%2C0%2C0%2C0%2C.415%2C1.079c.1.1%2C10.287%2C10.709%2C14.332%2C24.908a96.389%2C96.389%2C0%2C0%2C1%2C3.3%2C26.006%2C1.382%2C1.382%2C0%2C0%2C0%2C1.194%2C1.512l.049%2C0h21.212l4.031%2C18.549a1.3%2C1.3%2C0%2C0%2C0%2C1.207%2C1.1H636.166l3.194%2C12.707a1.366%2C1.366%2C0%2C0%2C0%2C.838.98%2C1.06%2C1.06%2C0%2C0%2C0%2C.356.062h45.753a1.365%2C1.365%2C0%2C0%2C0%2C1.243-1.458%2C1.571%2C1.571%2C0%2C0%2C0-.422-1.092Zm-472.8-72.072H202.078l9.808-9.737Zm424.427%2C60.874h12.257l-9.808%2C9.737Z%22%20transform%3D%22translate(-165.547%20-463.439)%22%2F%3E%3C%2Fsvg%3E');
      background-size: 476px 60px;
      color: #ffffff;
      font-weight: 600;
      span {
        color: #fffb8e;
        display: flex;
        font-size: 1.625em;
        padding: 0 2px 5px;
      }
    }
    .p-cta-btns {
      display: flex;
      justify-content: space-between;
      width: 400px;
      margin: 20px auto 0;
      button {
        width: 180px;
        margin: 0;
      }
    }
  }
  ${BreakPoints.medium} {
    .p-cta-inner {
      h3 {
        font-size: 2em;
      }
      .p-cta-ribbon {
        width: 360px;
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22522.004%22%20height%3D%2288.367%22%20viewBox%3D%220%200%20522.004%2088.367%22%3E%20%3Cdefs%3E%20%3Cstyle%3E%20.cls-1%20%7B%20fill%3A%20%23009893%3B%20%7D%20%3C%2Fstyle%3E%20%3C%2Fdefs%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_2240%22%20data-name%3D%22%E3%83%91%E3%82%B9%202240%22%20class%3D%22cls-1%22%20d%3D%22M687.13%2C549.26c-.1-.106-10.287-10.71-14.332-24.91a96.34%2C96.34%2C0%2C0%2C1-3.3-26.006%2C1.383%2C1.383%2C0%2C0%2C0-1.189-1.512H647.051L643.02%2C478.28a1.3%2C1.3%2C0%2C0%2C0-1.205-1.1H216.928l-3.194-12.708a1.366%2C1.366%2C0%2C0%2C0-.838-.981%2C1%2C1%2C0%2C0%2C0-.358-.056H166.787a1.367%2C1.367%2C0%2C0%2C0-1.24%2C1.464%2C1.574%2C1.574%2C0%2C0%2C0%2C.415%2C1.079c.1.1%2C10.287%2C10.709%2C14.332%2C24.908a96.389%2C96.389%2C0%2C0%2C1%2C3.3%2C26.006%2C1.382%2C1.382%2C0%2C0%2C0%2C1.194%2C1.512l.049%2C0h21.212l4.031%2C18.549a1.3%2C1.3%2C0%2C0%2C0%2C1.207%2C1.1H636.166l3.194%2C12.707a1.366%2C1.366%2C0%2C0%2C0%2C.838.98%2C1.06%2C1.06%2C0%2C0%2C0%2C.356.062h45.753a1.365%2C1.365%2C0%2C0%2C0%2C1.243-1.458%2C1.571%2C1.571%2C0%2C0%2C0-.422-1.092Zm-472.8-72.072H202.078l9.808-9.737Zm424.427%2C60.874h12.257l-9.808%2C9.737Z%22%20transform%3D%22translate(-165.547%20-463.439)%22%2F%3E%3C%2Fsvg%3E');
        background-size: cover;
        span {
          display: flex;
          font-size: 1.625em;
          padding: 0 2px 5px;
        }
      }
      .p-cta-btns {
        width: 100vw;
        max-width: 400px;
        button {
          padding: 0;
          margin: 0 5px;
          &.mega {
            padding: 0 40px;
          }
        }
      }
    }
  }
`

/**
 * サービスサイトCTAエリア
 * @constructor
 */
const OfficialCta = () => {
  const routeParam = useRouteParam('https://sign-up.kintaicloud.jp/sign-up')
  const routeParam2 = useRouteParam('/download')
  const routeParam3 = useRouteParam('/contact')
  return (
    <Wrapper className="anim-fade-up left-in">
      <div className="p-cta-inner anim-fade-up delay-1">
        <h3>今すぐキンクラを利用開始</h3>
        <div className="p-cta-ribbon">
          今なら<span>全機能が無料</span>で使える
        </div>
        <OfficialCommonBtn
          size="mega"
          level="strong"
          onClick={() => window.open(routeParam)}
        >
          無料トライアルはこちら
        </OfficialCommonBtn>
        <div className="p-cta-btns">
          <OfficialCommonBtn
            size="large"
            onClick={() => {
              navigate(routeParam2)
            }}
          >
            資料ダウンロード
          </OfficialCommonBtn>
          <OfficialCommonBtn
            size="large"
            onClick={() => {
              navigate(routeParam3)
            }}
          >
            お問い合わせ
          </OfficialCommonBtn>
        </div>
      </div>
    </Wrapper>
  )
}

export default OfficialCta
