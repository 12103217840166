import styled from '@emotion/styled'
import { WindowLocation } from '@reach/router'
import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import BreakPoints from '../../styles/breakPoints'
import ScrollTop from '../atoms/scrollTop'
import TopicPath, { ITopicPath } from '../atoms/topicPath'
import OfficialCta from './officialCta'
import OfficialFooter from './officialFooter'
import OfficialHeader from './officialHeader'

const Wrapper = styled.article`
  font-size: 16px;
  ${BreakPoints.large} {
    font-size: 14px;
  }
  ${BreakPoints.small} {
  }
`

type Props = {
  /** window.location */
  location: WindowLocation
  /** パンくずパス */
  paths?: ITopicPath[]
  /** ページ内容 */
  children: ReactNode
  /** クラス名 */
  className?: string
}

/**
 * サービスサイトメインレイアウト
 * @constructor
 */
const OfficialLayout = ({ location, paths, children, className }: Props) => {
  const rootPath = '/'
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = <OfficialHeader className={className} />
  } else {
    header = <OfficialHeader className={`${className} low`} />
  }

  return (
    <div id="pageTop">
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"
        />
        <script>
          {`
            function showElementAnimation() {

              var element = document.querySelectorAll('.anim-fade-up');
              if(!element) return;

              var showTiming = window.innerHeight > 768 ? 120 : 40; 
              var scrollY = window.pageYOffset;
              var windowH = window.innerHeight;

              for(var i=0;i<element.length;i++) { var elemClientRect = element[i].getBoundingClientRect(); var elemY = scrollY + elemClientRect.top; if(scrollY + windowH - showTiming > elemY) {
                element[i].classList.add('is-show');
              }
            }
          }
          showElementAnimation();
          window.addEventListener('scroll', showElementAnimation);
        `}
        </script>
      </Helmet>
      {header}
      <noscript>Javascriptが無効なため有効にしてください</noscript>
      <Wrapper>
        {paths && <TopicPath paths={paths} />}
        {children}
      </Wrapper>
      <OfficialCta />
      <OfficialFooter />
      <ScrollTop />
    </div>
  )
}

export default OfficialLayout
